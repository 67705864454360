<template>
    <section v-loading="m__loading" class="component-box">
        <div class="flex items-center justify-between mb-4 space-x-2">
            <div>
                <p class="font-semibold">
                    {{ $t('common.tiktok_shops') }}
                </p>
            </div>

            <div>
                <el-button size="small" @click="openTSPOnNewTab">
                    {{ $t('page.shop.register_new_tts') }}
                </el-button>

                <el-button
                    :disabled="!current_package || !m__selected_tiktok_business"
                    type="primary"
                    size="small"
                    icon="el-icon-s-shop"
                    @click="show_dialog.add_shop = true"
                >
                    {{ $t('page.shop.manage_tiktok_shop') }}
                </el-button>
            </div>
        </div>

        <el-alert
            :title="$t('page.shop.info')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert>

        <!-- <p class="text-sm text-red mb-4">
            <i class="el-icon-info text-desc-text"></i>

            {{ $t('page.shop.info') }}
        </p> -->

        <el-table :data="list_request_access_tiktok_shops">
            <el-table-column label="#" type="index" width="60" />

            <el-table-column :label="$t('page.shop.shop_code')" width="160">
                <template slot-scope="scope">
                    {{ scope.row.shop_code }}
                </template>
            </el-table-column>

            <el-table-column label="ID Tiktok" width="160">
                <template slot-scope="scope">
                    {{ scope.row.shop_id }}
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('page.shop.status_connect')"
                width="400"
            >
                <template slot-scope="scope">
                    <div class="font-semibold">
                        <p>
                            {{ $t('button.send_request') }} :

                            <span
                                :class="[
                                    scope.row.status === 'SUCCESS'
                                        ? 'text-success'
                                        : 'text-red'
                                ]"
                            >
                                {{
                                    $t(
                                        `page.shop.${scope.row.status.toLowerCase()}`
                                    )
                                }}
                            </span>
                        </p>
                        <p>
                            {{ $t('page.shop.connect_shop') }} :

                            <span
                                :class="[
                                    scope.row.shop_id
                                        ? 'text-success'
                                        : 'text-red'
                                ]"
                            >
                                {{
                                    scope.row.shop_id
                                        ? `${$t(`page.shop.has_connected`)}`
                                        : `${$t(`page.shop.hasnt_connected`)}`
                                }}
                            </span>
                        </p>
                        <!-- <p>
                            {{ $t('page.shop.connect_tiktok_account') }} :

                            <span
                                :class="[
                                    scope.row.has_catalog
                                        ? 'text-success'
                                        : 'text-red'
                                ]"
                            >
                                {{
                                    scope.row.has_catalog
                                        ? `${$t(`page.shop.has_connected`)}`
                                        : `${$t(`page.shop.hasnt_connected`)}`
                                }}
                            </span>
                        </p> -->
                    </div>
                </template>
            </el-table-column>

            <el-table-column :label="$t('common.error_message')" width="280">
                <template slot-scope="scope">
                    {{ scope.row.error_message }}
                </template>
            </el-table-column>

            <el-table-column :label="$t('common.created_at')" width="120">
                <template slot-scope="scope">
                    {{
                        scope.row.created_at
                            | f__format_moment($t('common.format_date'))
                    }}
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('common.option')"
                fixed="right"
                min-width="300"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.submit_url"
                        size="small"
                        @click="handleSubmitUrl(scope.row.submit_url)"
                    >
                        {{ $t('page.shop.submit_url') }}
                    </el-button>
                    <el-button
                        v-if="
                            scope.row.status.toLowerCase() === 'success' &&
                            !scope.row.shop_id
                        "
                        size="small"
                        :disabled="
                            has_connecting || !m__selected_tiktok_business
                        "
                        @click="handleConnectTiktokShop(scope.row.shop_code)"
                    >
                        {{ $t('page.shop.connect_shop') }}
                    </el-button>

                    <el-button
                        type="danger"
                        size="small"
                        @click="
                            deleteRequestAccessTiktokShop(scope.row.shop_code)
                        "
                    >
                        {{ $t('button.delete') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="flex items-center justify-between mt-2">
            <el-pagination
                :current-page.sync="m__page_info.page"
                :page-size="m__page_info.page_size"
                layout="prev, pager, next, total"
                :total="m__page_info.total_number"
            />
            <div class="flex items-center">
                <el-switch
                    v-model="switch_auto_refresh"
                    @change="handleToggleAutoRefresh"
                ></el-switch>
                <p class="text-xs mx-1">
                    {{ $t('page.campaign.auto_refresh') }}
                </p>
                <el-tooltip
                    effect="dark"
                    :content="$t('page.campaign.desc_auto_refresh')"
                    placement="top"
                >
                    <i class="el-icon-info text-desc-text"></i>
                </el-tooltip>
            </div>
        </div>

        <el-dialog
            v-loading="loading.add_shop"
            :visible.sync="show_dialog.add_shop"
            :append-to-body="true"
            :close-on-click-modal="true"
            width="600px"
        >
            <div slot="title">
                <div class="text-left truncate w-4/5 text-base font-semibold">
                    {{ $t('page.shop.dialog_title') }}
                </div>
            </div>

            <div>
                <div class="font-bold">
                    {{ $t('page.shop.dont_have_tts') }}
                    <a
                        href="javascript:void(0)"
                        class="text-primary"
                        @click="openTSPOnNewTab"
                    >
                        {{ $t('page.shop.register_now') }}</a
                    >
                </div>

                <div class="mt-6" style="word-break: break-word">
                    {{ $t('page.shop.dialog_note') }}
                </div>

                <div class="mt-8">
                    <div class="flex">
                        <el-tooltip placement="top" effect="light">
                            <div
                                slot="content"
                                class="w-[320px] text-sm leading-[1.5rem]"
                            >
                                <span class="font-bold">{{
                                    $t('page.shop.how_find_it_title')
                                }}</span>
                                <br />
                                <span class="font-normal">{{
                                    $t('page.shop.how_find_it_message')
                                }}</span>
                            </div>
                            <span
                                class="text-primary mb-1 ml-auto cursor-pointer"
                            >
                                {{ $t('page.shop.how_find_it') }}
                            </span>
                        </el-tooltip>
                    </div>
                    <el-input
                        v-model="form_add_shop.shop_code"
                        size="small"
                        :placeholder="$t('page.shop.enter_shop_code')"
                    ></el-input>
                </div>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        size="small"
                        @click="show_dialog.add_shop = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        type="primary"
                        size="small"
                        @click="requestAccessTiktokShop"
                    >
                        {{ $t('button.send_request') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </section>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import {
    deleteRequestAccessTiktokShop,
    getRequestAccessTiktokShop,
    requestAccessTiktokShop
} from '@/services/atosa-tiktok-ads/store'
import { authTiktokSeller } from '@/services/atosa-tiktok-ads/tiktok-seller'
import { getSellerAuthenticationLink } from '@/services/atosa-tiktok-ads/tiktok-account'

export default {
    mixins: [
        selectedTiktokBusinessMixin,
        selectedBusinessCenterMixin,
        paginationDataMixin
    ],

    data() {
        return {
            polling: null,
            switch_auto_refresh: true,
            has_connecting: false,
            loading: {
                add_shop: false
            },
            show_dialog: {
                add_shop: false
            },
            form_add_shop: {
                target_bc_id: '',
                shop_code: ''
            },
            list_request_access_tiktok_shops: []
        }
    },

    computed: {
        current_package() {
            return this.$store.getters.currentPackage.s__current_package
        },

        s__end_domain() {
            return this.$store.getters.app.s__end_domain
        },

        s__language() {
            return this.$store.getters.app.s__language
        }
    },

    async mounted() {
        await this.fetchRequestAccessTiktokShop()

        this.handleToggleAutoRefresh()
    },

    beforeDestroy() {
        clearInterval(this.polling)
    },

    methods: {
        handleToggleAutoRefresh() {
            if (this.switch_auto_refresh) {
                this.polling = setInterval(() => {
                    this.fetchRequestAccessTiktokShop()
                }, 10e3)
            } else {
                clearInterval(this.polling)
            }
        },

        openTSPOnNewTab() {
            window.open(
                'https://seller-vn.tiktok.com/account/register?channel=Atosa_TSP',
                '_blank'
            )
        },

        async fetchRequestAccessTiktokShop() {
            this.m__loading = true
            try {
                const response = await getRequestAccessTiktokShop()
                this.list_request_access_tiktok_shops = response.data
            } catch (e) {
                console.error(e)
            } finally {
                this.m__loading = false
            }
        },

        async requestAccessTiktokShop() {
            if (!this.m__selected_tiktok_business) return

            if (!this.form_add_shop.shop_code) return

            this.loading.add_shop = true
            try {
                await requestAccessTiktokShop(
                    this.m__selected_tiktok_business.id,
                    this.form_add_shop
                )
                await this.fetchRequestAccessTiktokShop()
                this.p__showNotify('success', this.$t('page.shop.request_sent'))
                this.form_add_shop.shop_code = ''
                this.form_add_shop.target_bc_id = ''
                this.show_dialog.add_shop = false
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.add_shop = false
            }
        },

        async handleConnectTiktokShop(shop_code) {
            const url = `${process.env.VUE_APP_ATOSA_TIKTOK_SELLER_AUTH_URL}&state=${this.$store.state.user.s__user_info.token}-----${shop_code}-----${this.m__selected_tiktok_business.id}-----${this.s__end_domain}-----${this.s__language}`

            window.location = url
        },

        async handleSubmitUrl(submit_url) {
            await this.p__OpenLinkOnNewWindow(submit_url)
        },

        // async handleConnectTiktokAdsAccount(shop_id) {
        //     this.has_connecting = true

        //     try {
        //         const response = await getSellerAuthenticationLink()

        //         await this.p__OpenLinkOnNewWindow(
        //             response.data.link.replace(
        //                 'state=tiktok_shop',
        //                 `state=${this.$store.state.user.s__user_info.token}-----${shop_id}`
        //             )
        //         )

        //         this.fetchRequestAccessTiktokShop()

        //         // this.p__showNotify(
        //         //     'success',
        //         //     this.$t('page.shop.connect_tiktok_account_success')
        //         // )
        //     } catch (error) {
        //         console.error(error)
        //     }

        //     this.has_connecting = false
        // },

        // async authenTtShop(auth_code, shop_code) {
        //     try {
        //         const response = await authTiktokSeller(
        //             this.m__selected_tiktok_business.id,
        //             {
        //                 auth_code,
        //                 shop_code
        //             }
        //         )

        //         if (response.code === 0) {
        //             await this.fetchRequestAccessTiktokShop()

        //             this.p__showNotify(
        //                 'success',
        //                 this.$t('page.shop.connect_tiktok_shop_success')
        //             )
        //         }
        //     } catch (error) {
        //         setTimeout(() => {
        //             window.open(
        //                 `${this.p__group_route.ads_manager}/${this.p__route.tiktok_shop}`,
        //                 '_self'
        //             )
        //         }, 1500)
        //         console.error(error)
        //     }
        // },

        async deleteRequestAccessTiktokShop(shop_code) {
            if (!this.m__selected_tiktok_business) return

            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteRequestAccessTiktokShop(
                    this.m__selected_tiktok_business.id,
                    shop_code
                )
                await this.fetchRequestAccessTiktokShop()
                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>
